import ERROR_MESSAGES from '@/constants/errorMessages';
import { TICKET_STATE } from '@/constants/ticket';
import { UserTicketEntity } from '@/generated/graphql';
import { FieldErrors } from 'react-hook-form';
import { toast } from 'react-toastify';

export const getParentItem = (value: string | number | null, list: any[]) => {
  if (!value) return null;
  return list.find((item) => item.value === value);
};

export const getFormErrMessage = (name: string, err: FieldErrors) => err[name]?.message ?? '';

export const onCopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.info('クリップボードにコピーしました');
};

export const delay = (ms: number = 3000) => new Promise((resolve) => setTimeout(resolve, ms));

export const showError = (error: any) => {
  const errorCode = error.response.errors[0].extensions.code;
  toast.error(ERROR_MESSAGES[errorCode as keyof typeof ERROR_MESSAGES]);
};

export function isPwa() {
  const displayStandAlone = '(display-mode: standalone)';
  return window.matchMedia(displayStandAlone).matches || (navigator as any).standalone;
}

export const getTicketState = (userTicket: UserTicketEntity) => {
  const now = new Date().getTime();
  const consumeAbleStartAt = new Date(userTicket?.ticket.consumeableStartAt).getTime();
  const consumeAbleEndAt = new Date(userTicket?.ticket.consumeableEndAt).getTime();

  if (now > consumeAbleEndAt) return TICKET_STATE.EXPIRED;

  if (userTicket?.consumedAt) return TICKET_STATE.CONSUMED;

  if (now < consumeAbleStartAt) return TICKET_STATE.NOT_CONSUMABLE;

  return TICKET_STATE.CONSUMABLE;
};

export const getYoutubeId = (url: string) => {
  if (!url) return '';
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};
