import { metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { mainnet } from 'wagmi/chains';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { createConfig, http } from 'wagmi';
import { applicationProperties } from '@/constants/applicationProperties';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet],
    },
  ],
  {
    appName: 'Asteeda',
    projectId: applicationProperties.WALLET_CONNECT_PROJECT_ID,
  }
);

export const config = createConfig({
  connectors,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
  ssr: true, // If your dApp uses server side rendering (SSR)
});
