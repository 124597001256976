export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  NO_ANSWER: 'noAnswer',
};

export const GenderOptions = [
  { value: GENDER.MALE, label: '男性' },
  { value: GENDER.FEMALE, label: '女性' },
  { value: GENDER.NO_ANSWER, label: '無回答' },
];

export const STORAGE_KEY = {
  FIRST_LOAD_SESSION: 'firstLoadSession',
};

export const PRIVACY_POLICY_LINK = 'https://www.scsk.jp/privacy.html';
export const OUTER_URLS = {
  PRIVACY: 'https://www.scsk.jp/privacy.html',
  TERM: 'https://drive.google.com/file/d/19R26joGpQMfNBAkT4fDwb2EFy0FW0b0_/view',
  HOW_TO_USE: 'https://sandy-dresser-c92.notion.site/for-web-f6ec770bbaf34a40b0b5dd8d40a49f2f',
  FAQ: 'https://sandy-dresser-c92.notion.site/for-web-926e9bb3ad674185a31e306ef740d904?pvs=4',
};

export const INQUIRY_EMAIL_ADDRESS = 'nftatokara-support@scsk.jp';

export enum HIGHLIGHT_TYPE {
  YOUTUBE = 'youtube',
  IMAGE = 'image',
}
